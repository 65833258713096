import {useState} from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        return JSON.parse(tokenString)
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        sessionStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
    };

    const clearToken = () => {
        sessionStorage.removeItem('token')
        setToken(undefined)
    }

    return {
        setToken: saveToken,
        token,
        clearToken: clearToken,
    }
}
